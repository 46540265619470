import React from "react"
import Video from "../components/video.js"

const VideoGrid = ({ videos }) => {
  
  return (
  <div className="grid md:grid-cols-2">
  {videos.map((video, i) => {
    
    return <Video key={i} video={video} />
    
  })}
</div>
  )
}

export default VideoGrid;