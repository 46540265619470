import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import VideoGrid from "../components/videoGrid";
import Header from "../components/header";
import AllWorkFooter from "../components/allWorkFooter"
import Fade from "react-reveal"

const PageTemplate = ({ data }) => {
  const teamMember = data.allStrapiTeammembers.edges[0].node;
  const teamMemberVideos = teamMember.team_member_videos.map(teamMember => teamMember.video);
  const fullName = `${teamMember.first_name} ${teamMember.last_name}`

            
  return (
    <Layout>
      <Seo title="Team Member Profile" />
      <div>
        <Header />
        <Fade>
          <div className="text-center md:text-left md:flex justify-center w-11/12 md:w-10/12 lg:w-3/4 mx-auto items-center mb-20 pt-32 md:pt-40 lg:pt-48">
            <img className="w-3/5 md:w-2/5 xl:w-4/12 flex-none mb-5 md:mb-0 mx-auto md:mx-0 rounded-full" src={teamMember.headshot.publicURL} alt={fullName}/>
            <div className="md:ml-12 lg:ml-20">
              <h5 className="font-medium text-pink text-4xl lg:text-6xl">meet</h5>
              <h3 className="font-medium text-5xl md:text-6xl lg:text-8xl leading-none pb-4 lg:pb-8 gradient-text inline-block w-11/12 md:w-full mx-auto md:m-0">
                {teamMember.first_name}<br/>{teamMember.last_name}
              </h3>
              <p className="text-white text-xl md:text-1xl lg:text-2xl pb-3">{teamMember.title}</p>
              <p className="text-white">{teamMember.office.city_state}</p>
            </div>
          </div>
        </Fade>
  
        <div className={`w-11/12 md:w-10/12 lg:w-3/5 m-auto ${teamMemberVideos.length > 0 ? 'pb-20' : ''}`}>
          <Fade><p className="text-white text-base lg:text-1.5xl text-center leading-normal mx-auto">{teamMember.bio}</p></Fade>
        </div>

        {teamMemberVideos.length > 0 && (
          <VideoGrid videos={teamMemberVideos} />
        )}
        <AllWorkFooter />
      </div>
    </Layout>
  )
}

export default PageTemplate;

export const query = graphql`
  query($lastName: String!, $firstName: String!) {
    allStrapiTeammembers(
      filter: { last_name: { eq: $lastName }, first_name: { eq: $firstName } }
    ) {
      edges {
        node {
          first_name
          title
          last_name
          office {
            city_state
          }
          headshot {
            publicURL
          }
          bio
          team_member_videos {
            video {
              id
              animator
              director
              editor
              photographer
              producer
              retoucher
              shooter
              poster_image {
                publicURL
              }
              photography {
                url
                alternativeText
                formats {
                  large {
                    publicURL
                    relativePath
                  }
                }
              }
              title
              client
              vimeoID
              categories {
                category_name
              }
            }
          }
        }
      }
    }
  }
`